<template>
  <div class="ob-ed">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new w-100">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img
                src="@/assets/images/onboarding/collaborate.svg"
                alt="collaborate"
              />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.affiliation.heading") }}
            </h2>
            <div class="ob-main__sec">
              <b-form @submit="onNext">
                <ValidationObserver v-slot="{ invalid }">
                  <b-row class="ob-sec__dropdown">
                    <b-col cols sm="12" md="6" class="ob-responsive__view">
                      <ValidationProvider
                        name="Affiliation"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <LxpDropdown
                          :key="key"
                          :label="$t('onboarding.affiliation.label')"
                          :placeholder="$t('onboarding.affiliation.heading')"
                          :dropdownArr="
                            affiliation.filter(i => i.valid === true)
                          "
                          :value="
                            form.affiliation ? form.affiliation.name : undefined
                          "
                          @click="onSelect($event, 'affiliation')"
                          :isFloating="true"
                        />
                        <span class="error mb-2">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <Footer
                    :invalid="invalid"
                    ref="footer"
                    :buttonText="$t('general.next')"
                  />
                </ValidationObserver>
              </b-form>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingLayout from "../Layout/OnboardingLayout";
import OnboardingMixin from "@/components/Onboarding/mixin";
import Footer from "@/components/Layout/OnboardingPageLayout/Footer.vue";
import { LxpDropdown } from "didactica";

export default {
  mixins: [OnboardingMixin],
  components: { OnboardingLayout, LxpDropdown, Footer },
  data() {
    return {
      key: 0,
      isCompleted: false,
      form: {
        affiliation: ""
      },
      currentStep: 0,
      showFreeText: false
    };
  },
  computed: {
    ...mapGetters(["affiliation", "allProfile"])
  },
  mounted() {
    this.currentStep = this.step;
    this.initData();
  },
  methods: {
    initData() {
      const profile = this.allProfile;
      if (profile && profile.affiliation) {
        this.form.affiliation = profile.affiliation;
        this.setNextFocus();
        this.key++;
      }
    },
    onNext(event) {
      event.preventDefault();
      let obj = {
        affiliation:
          this.form.affiliation !== "" ? this.form.affiliation.id : null
      };
      this.updateOnboarding(obj, this.form);
    },
    setNextFocus() {
      this.$refs.footer.setNextFocus();
    },
    onSelect(e, type) {
      this.form[type] = e;
      // set the focus to next button for enter key to work
      setTimeout(() => {
        this.setNextFocus();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
